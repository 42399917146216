import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const privacyHtmlContent = (
  <div className="col-md-12 mx-auto">
    <p className="lead">
      We take your privacy and the security of information about you seriously.
      This Online
      <a href="#online-privacy-notice"> Privacy Policy</a> explains how we
      collect and use personal information through our website,
      http://trustic.com, mobile application(s) and all related applications,
      services and tools (collectively, our “Service”), including how we may use
      such information for advertising purposes. Our data sharing practices are
      also governed by the Trustic
      <a href="#privacy-notice"> Privacy Notice</a>, which explains the choices
      you may have in the use and sharing of information about you.
    </p>
    <p className="lead">
      This policy is incorporated into Trustic’s{" "}
      <Link to="/terms">Terms of Use</Link>, and your consent to this policy is
      a material term of our <Link to="/terms">Terms of Use</Link>. By accessing
      or using our Service, you signify that you have read, understood, and
      agree to our collection, storage, use, and disclosure of personal
      information about you as described in this Online
      <a href="#online-privacy-notice"> Privacy Policy</a>, our
      <a href="#privacy-notice"> Privacy Notice</a>, and our{" "}
      <Link to="/terms">Terms of Use</Link>.
    </p>
    <h3 className="mb-6 display-5" id="privacy-notice">
      Privacy Notice
    </h3>
    <p className="lead">
      This<a href="#privacy-notice"> Privacy Notice</a> applies to your
      interaction with Nextate Credit, LLC, DBA Trustic, or any of its
      affiliates (collectively "Trustic," "we," "us," "our"). This
      <a href="#privacy-notice"> Privacy Notice</a> is effective as of May 20,
      2021.
    </p>
    <table className="table table-bordered">
      <tbody>
        <tr className="bg-dark text-white fw-800">
          <td>FACTS</td>
          <td>WHAT DOES TRUSTIC DO WITH YOUR PERSONAL INFORMATION?</td>
        </tr>
        <tr>
          <td>Why</td>
          <td>
            Financial companies choose how they share your personal information.
            Federal law gives consumers the right to limit some but not all
            sharing. Federal law also requires us to tell you how we collect,
            share, and protect your personal information. Please read this
            notice carefully to understand what we do.
          </td>
        </tr>
        <tr>
          <td>What?</td>
          <td>
            The types of personal information we collect and share depend on the
            product or service you have with us. This information can include:
            <ul>
              <li>Social Security number and income</li>
              <li>Account balances and transaction history</li>
              <li>Credit scores and employment information</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>How?</td>
          <td>
            All financial companies need to share customers' personal
            information to run their everyday business. In the section below, we
            list the reasons financial companies can share their customers'
            personal information; the reasons Trustic chooses to share; and
            whether you can limit this sharing.
          </td>
        </tr>
      </tbody>
    </table>
    <table className="table table-bordered">
      <tbody>
        <tr className="bg-dark text-white fw-800">
          <td>Reasons we can share your personal information</td>
          <td>Does Trustic share?</td>
          <td>Can you limit this sharing?</td>
        </tr>
        <tr>
          <td>
            <b>For our everyday business purposes</b> - such as to process your
            transactions, maintain your account(s), respond to court orders and
            legal investigations, or report to credit bureaus
          </td>
          <td>Yes</td>
          <td>No</td>
        </tr>
        <tr>
          <td>
            <b>For our marketing purposes</b> - to offer our products and
            services to you
          </td>
          <td>Yes</td>
          <td>No</td>
        </tr>
        <tr>
          <td>
            <b>For joint marketing with other financial companies</b>
          </td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>
            <b>For our affiliates' everyday business purposes</b> - information
            about your transactions and experiences
          </td>
          <td>Yes</td>
          <td>No</td>
        </tr>
        <tr>
          <td>
            <b>For our affiliates' everyday business purposes</b> - information
            about your creditworthiness
          </td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>
            <b>For our affiliates to market to you </b>
          </td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>
            <b>For nonaffiliates to market to you</b>
          </td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>To limit our sharing</td>
          <td colSpan="2">
            Call 888-429-0480 or contact us at
            <br />
            <a href="mailto:privacy@trustic.com">privacy@trustic.com</a>
            <br />
            <br />
            <h4>Please note:</h4>
            <p className="lead">
              If you are a new customer, we can begin sharing your information
              30 days from the date we sent this notice. When you are no longer
              our customer, we continue to share your information as described
              in this notice. However, you can contact us at any time to limit
              our sharing.
            </p>
          </td>
        </tr>
        <tr>
          <td>Questions?</td>
          <td colSpan="2">
            Call <a href="mailto:privacy@trustic.com ">privacy@trustic.com </a>
            or go to http://trustic.com
          </td>
        </tr>
      </tbody>
    </table>

    <table className="table table-bordered">
      <tbody>
        <tr className="bg-dark text-white fw-800">
          <td colSpan="2">Who we are</td>
        </tr>
        <tr>
          <td>
            <b>Who is providing this notice?</b>
          </td>
          <td>Nextate Credit LLC, DBA Trustic</td>
        </tr>
      </tbody>
    </table>

    <table className="table table-bordered">
      <tbody>
        <tr className="bg-dark text-white fw-800">
          <td colSpan="2">What we do</td>
        </tr>
        <tr>
          <td>
            <b>How does Trustic protect my personal information?</b>
          </td>
          <td>
            To protect your personal information from unauthorized access and
            use, we use security measures that comply with federal law. These
            measures include computer safeguards and secured files and
            buildings.
          </td>
        </tr>

        <tr>
          <td>
            <b>How does Trustic collect my personal information?</b>
          </td>
          <td>
            We collect your personal information, for example, when you
            <ul>
              <li>Check you rate or apply for a loan</li>
              <li>Register to act as a guarantor</li>
              <li>Give us your contact information </li>
              <li>Give us your income information</li>
              <li>Show your government-issued ID</li>
            </ul>
            We also collect information about you from others, such as credit
            bureaus and other companies.
          </td>
        </tr>

        <tr>
          <td>
            <b>Why can't I limit all sharing?</b>
          </td>
          <td>
            Federal law gives you the right to limit only
            <ul>
              <li>
                sharing for affiliates' everyday business purposes - information
                about your creditworthiness
              </li>
              <li>affiliates from using your information to market to you</li>
              <li>sharing for nonaffiliates to market to you</li>
            </ul>
            State laws and individual companies may give you additional rights
            to limit sharing.
          </td>
        </tr>
        <tr>
          <td>
            <b>
              What happens when I limit sharing for an account I hold jointly
              with someone else?
            </b>
          </td>
          <td>
            Your choices will apply to everyone on your account - unless you
            tell us otherwise.
          </td>
        </tr>
      </tbody>
    </table>

    <table className="table table-bordered">
      <tbody>
        <tr className="bg-dark text-white fw-800">
          <td colSpan="2">Definitions</td>
        </tr>
        <tr>
          <td>
            <b>Affiliates</b>
          </td>
          <td>
            Companies related by common ownership or control. They can be
            financial and nonfinancial companies.
          </td>
        </tr>
        <tr>
          <td>
            <b>Nonaffiliates</b>
          </td>
          <td>
            Companies not related by common ownership or control. They can be
            financial and nonfinancial companies. Nonaffiliates with whom we
            share can include other financial services companies, lenders,
            insurance companies, retailers, membership clubs, or other consumer
            service providers such as data processors and companies that help us
            market our own products and third-party products that we believe may
            be of interest to you.
          </td>
        </tr>
        <tr>
          <td>
            <b>Joint marketing</b>
          </td>
          <td>
            A formal agreement between nonaffiliated financial companies that
            together market financial products or services to you. Our joint
            marketing partners include other financial services companies,
            banks, lenders, insurance companies, or other consumer service
            providers.
          </td>
        </tr>
      </tbody>
    </table>
    <table className="table table-bordered">
      <tbody>
        <tr className="bg-dark text-white fw-800">
          <td colSpan="2">Other important information</td>
        </tr>
        <tr>
          <td>
            <b>For California residents</b>
          </td>
          <td>
            We do not sell personal information under California law. We will
            not share information we collect about you with nonaffiliated third
            parties, except as required or permitted by law, such as to process
            your transactions, to maintain your account, or for other purposes
            described in our Online
            <a href="#online-privacy-notice"> Privacy Policy</a>. We will limit
            sharing within our corporate family to the extent required by
            California law. To make a request under California privacy law, you
            may email us at{" "}
            <a href="mailto:privacy@trustic.com">privacy@trustic.com</a> for
            more information.
          </td>
        </tr>
        <tr>
          <td>
            <b>For Vermont residents</b>
          </td>
          <td>
            We will not share information we collect about you with
            nonaffiliated third parties, except as permitted by Vermont law,
            such as to process your transactions or to maintain your account. We
            will not share information about your creditworthiness with our
            affiliates except with your authorization or as required or
            permitted by law. We may share information about our transactions or
            experiences with you within our corporate family without your
            consent.
          </td>
        </tr>
        <tr>
          <td>
            <b>For Nevada residents</b>
          </td>
          <td>
            We provide you this notice under Nevada state law. You may be placed
            on our internal Do-Not-Call List by calling us at 888-429-0480 or
            emailing us at{" "}
            <a href="mailto:privacy@trustic.com">privacy@trustic.com</a> or
            writing us at:
            <br /> Nextate Credit, LLC Attn: Compliance Department
            <br />
            Corporation Trust Center, 1209 Orange Street, Wilmington, DE 19801
            <br />
            Nevada law also requires us to provide you the following to obtain
            additional information: Office of the Attorney General 100 North
            Carson Street Carson City, NV 89701 Telephone: 775-684-1100 Email:{" "}
            <a href="mailto:AgInfo@ag.state.nev.us">AgInfo@ag.state.nev.us</a>
          </td>
        </tr>
      </tbody>
    </table>
    <h3 className="mb-6 display-5" id="online-privacy-notice">
      Online Privacy Policy
    </h3>
    <p className="lead">
      This Online<a href="#online-privacy-notice"> Privacy Policy</a> applies to
      all online and offline interactions with Nextate Credit, LLC, DBA Trustic
      or any of its affiliates (collectively "Trustic," "we," "us," "our") as
      more specifically noted below and our practices relating to the personal
      information we collect from you. This Online Privacy Policy is effective
      as of May 20, 2021.
    </p>
    <h3 className="mb-6 display-5">
      1. CATEGORIES OF PERSONAL INFORMATION WE COLLECT AND HOW WE USE IT
    </h3>
    <p className="lead">
      We collect information you provide Trustic when you interact with us
      directly or through a third-party, such as when you register for our
      Service, apply for a loan or to be a guarantor, sign up for our mailing
      list, or otherwise communicate with us. We use all of the information we
      collect to operate, maintain, improve and provide to you the features and
      functionality of the Service and to create new features. The types of
      personal information we collect depends on the products and Service you
      request and use. Below is a list of categories of personal information we
      collect and have collected and disclosed for a business purpose, the
      sources from which the information was collected, the business purposes
      for which the information was collected, and categories of third parties
      with whom the information was shared in the last 12 months.
    </p>
    <h4>Identifiers</h4>
    <p className="lead">
      <b>Types.</b> This information may include a real name, alias, postal
      address, telephone number, unique personal identifier, online identifier,
      Internet Protocol address, email address or other communication
      information, account name, social security number, driver's license number
      or state identification card number, passport number, or other similar
      identifiers or identity verification information. This information may
      also include club membership or reward-based program identifiers.
    </p>
    <p className="lead">
      <b>Sources.</b> We collect this information from you, and from third
      parties, including information we request from credit reporting agencies,
      and service providers. This information may include information you
      provide and that we receive through referral activities. With your
      permission, we may access and import your contact list available through
      your email accounts.
    </p>
    <p className="lead">
      <b>Purposes.</b> We use this information to verify your identity,
      facilitate transactions, to advertise to you, and to create your borrower
      or guarantor profile and account. We may use your contact list
      information, if you choose to provide it, so that we may invite your
      friends and contacts to connect with our Service and to provide credit to
      you for referrals. Certain personal information must be supplied during
      the guarantor and/or borrower registration processes in order to meet our
      legal obligations, facilitate credit eligibility decisions, protect
      against fraud and complete your transaction. We may use this information
      to communicate directly with you, such as to send you direct mail and
      email marketing messages or advertising or to suppress such messages. We
      may also send you Service-related emails or messages (e.g., account
      verification, change or updates to features of the Service, technical and
      security notices). For more information about your communication
      preferences, see "Control Over Information" below.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Trustic
      companies, third-party service providers, other third parties, originating
      banks, purchasers, prospective purchasers, credit bureaus, parties to a
      company transaction, and legally interested parties as described in
      Section 3 below.
    </p>
    <h4>Financial Information.</h4>
    <p className="lead">
      <b>Types.</b> This information may include payment and bank information,
      wire transfer information, credit card number, debit card number, full
      credit report, transactional information for financial accounts, account
      information including interest rates and balances, income information, and
      any other financial information available.
    </p>
    <p className="lead">
      <b>Sources.</b> We may receive financial information that you provide to
      us directly or through third-party services. We may also receive
      information about you from third parties, including information requested
      from credit reporting agencies such as TransUnion, Equifax, and/or
      Experian, and from other partners. We will collect information, which may
      include your credit history and credit scores, from credit bureaus and
      other partners.
    </p>
    <p className="lead">
      <b>Purposes.</b> We use this financial information to process your
      transaction, to improve and expand our Service, and to determine your
      financial health. If you register as a borrower, we will use financial
      information about you to facilitate your loan request and service your
      loan. If you register as a guarantor, we will use financial information
      about you to facilitate lending to a borrower. We use this information to
      process your loan application or transaction, and enable payments and fund
      transfers with financial institutions. We use this financial information
      for creditworthiness if you are a borrower or a guarantor, and assess
      risks related to your transaction. If you consent to have Trustic request
      your financial information directly from your financial institution, we
      may work with a third party, such as Plaid, to access and collect such
      information on our behalf. By providing your financial information to the
      third party, you acknowledge and agree that such information accessed,
      collected, or transmitted by the third party for this purpose will be
      governed by the privacy policy of the third party. Plaid's privacy policy
      is available at{" "}
      <a href="https://plaid.com/legal">https://plaid.com/legal</a>.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Trustic
      companies, third-party service providers, other third parties, originating
      banks, purchasers, prospective purchasers, credit bureaus, parties to a
      company transaction, and legally interested parties as described in
      Section 3 below.
    </p>
    <h4>Commercial Information.</h4>
    <p className="lead">
      <b>Types.</b> This information may include records of personal property,
      products or services purchased, obtained, or considered, or other
      purchasing or consuming histories or tendencies. This information may
      include details about property used to secure a loan, such as vehicle
      information and location. Commercial information may also include
      information about the utilities you use, rewards and points- based
      incentive programs, program membership information, and information about
      your mobile device and carrier.
    </p>
    <p className="lead">
      <b>Sources.</b> We may receive commercial information that you provide to
      us directly or through third-party services. We may receive information
      about your property from public records or from third parties, including
      information we request from credit reporting agencies, and from other
      partners. If you ask us to use your utility payment history to help
      determine your eligibility for our Service, then we may receive this
      information from your utility or a service provider that collects the
      information on our behalf. By providing your information to the third
      party, you acknowledge and agree that information accessed, collected, or
      transmitted by the third party for this purpose will be governed by the
      privacy policy of the third party. Financial transaction information may
      also include commercial information about purchases, purchasing or
      consuming habits and tendencies.
    </p>
    <p className="lead">
      <b>Purposes.</b> We use this information for creditworthiness and
      determining financial health, to improve and expand our Service, and
      perfecting security interests. For example, information about your
      automobile may be used to register a security interest if that automobile
      is used as collateral for a loan.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Trustic
      companies, third-party service providers, other third parties, originating
      banks, purchasers, prospective purchasers, credit bureaus, parties to a
      company transaction, and legally interested parties as described in
      Section 3 below.
    </p>
    <h4>Business Information.</h4>
    <p className="lead">
      <b>Types.</b> This information may include information about your
      business, such as legal business name and company practice name, and other
      information about your company or practice such as fax, telephone number,
      website, relevant email addresses, physical address, business-related
      licensing information such as a license number, federal tax identification
      or social security number, and business or practice bank account
      information. This information may be collected, for example, when you
      apply for our Service as self- employed.
    </p>
    <p className="lead">
      <b>Sources.</b> We may receive business information that you provide to us
      directly or through third-party services. We may receive information about
      your property from public records or from third parties, including
      information we request from credit reporting agencies, and from other
      partners. For example, we may also collect this information from your
      registration application submitted through provider agreement, or from a
      personal loan application if you are self-employed.{" "}
    </p>
    <p className="lead">
      <b>Purposes.</b> We use this information for processing your application,
      facilitating transactions, for verification purposes, and to improve or
      expand our Service. We use this information in connection with
      securitizations and other investment products with an interest in your
      loan. We may also use this information to enable our financial services
      partners to implement automatic payments.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Trustic
      companies, third-party service providers, other third parties, originating
      banks, purchasers, prospective purchasers, credit bureaus, parties to a
      company transaction, and legally interested parties as described in
      Section 3 below.
    </p>
    <h4>Network Activity and Location Information.</h4>
    <p className="lead">
      <b>Types.</b> This information may include, but is not limited to, your
      browsing history, search history, geolocation data, and information
      regarding your interaction with an Internet web site, application, or
      advertisement.
    </p>
    <p className="lead">
      <b>Sources.</b> We collect this information from you and from
      third-parties such as advertising service providers and marketing
      analytics and information service providers. This information may be
      passed to us via cookies, pixels, or other tracking technologies described
      in Section 3 below. Geolocation data may be collected from your device,
      your browser, or from other sources such as metadata stored in images or
      files you provide to us.
    </p>
    <p className="lead">
      <b>Purposes.</b> We use this information for security, processing your
      application, facilitating transactions, marketing, for verification
      purposes and to improve or expand our Services. For example, we use this
      information to better understand the effectiveness of our advertising
      activities and efficiency in our application process. We also use this
      information to better advertise to you and to track your use of our
      websites and the selections you make in order to satisfy regulatory
      obligations. Please refer to Section 2 below, which provides detailed
      information about our use of tracking technologies.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Trustic
      companies, third-party service providers, other third parties, parties to
      a company transaction, and legally interested parties as described in
      Section 3 below.
    </p>
    <h4>Audio, Visual, and Electronic Information.</h4>
    <p className="lead">
      <b>Types.</b> This information may include a picture of you, digital or
      digitized signature, or voice recordings from phone calls. We may also
      collect any other communications between you and Trustic.
    </p>
    <p className="lead">
      <b>Sources.</b> We collect this information from you or from
      third-parties. For example, we may collect a copy of a government issued
      ID that includes a picture from you, and we may collect a picture of you
      and your signature when you visit our offices. Your signature or digital
      or digitized signature may also be on documents we collect from you. We
      may collect this information from third-party service providers that
      provide member support and operations-related services.
    </p>
    <p className="lead">
      <b>Purposes.</b> We use this information for information security, fraud
      detection and prevention, quality control, and for processing your
      application, facilitating transactions, for verification purposes and to
      improve or expand our Services.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Trustic
      companies, third-party service providers, other third parties, originating
      banks, purchasers, prospective purchasers, parties to a company
      transaction, and legally interested parties as described in Section 3
      below.
    </p>
    <h4>Professional, Education, or Employment-related Information.</h4>
    <p className="lead">
      <b>Types.</b> This information may include work history, employer and
      employment information, profession, job title, work address and phone
      number, and other information related to your profession.
    </p>
    <p className="lead">
      <b>Sources.</b> We collect this information from you, and from third
      parties, including information we request from credit reporting agencies,
      and service providers. Information you may provide and that we receive
      through referral activities.
    </p>
    <p className="lead">
      <b>Purposes.</b> We use this information to determine your
      creditworthiness if you are a borrower or guarantor, assess risks related
      to your potential loan, and to help investors determine whether to commit
      to or purchase your loan. We may also use this information to enable or
      implement automatic payments. We may use this information for verification
      purposes, to improve or expand our Service, and for offering products and
      Service to you. This information may be used as professional information.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Nextate
      companies, third-party service providers, other third parties, originating
      banks, purchasers, prospective purchasers, credit bureaus, parties to a
      company transaction, and legally interested parties as described in
      Section 3 below.
    </p>
    <h4>Other Sensitive Information</h4>
    <p className="lead">
      <b>Types.</b> This information includes other information related to your
      transactions with us or provided to us through linked accounts and
      systems, such as information about medical or educational services
      purchased with a loan, insurance information such as automotive insurance
      numbers relating to an automobile loan or health insurance information.
      This information may also include detailed information about your
      purchases from bank, credit card, and other financial or transactional
      accounts.
    </p>
    <p className="lead">
      <b>Sources.</b> We may receive other sensitive information that you
      provide to us directly or through third-party services such as healthcare
      and educational service providers. This information may be included in
      application information, and may also be provided as part of a transaction
      with a service provider.
    </p>
    <p className="lead">
      <b>Purposes.</b> We use this information for processing your loan
      application, facilitating transactions, for verification purposes,
      regulatory requirements, to improve or expand our Services, and to offer
      products and Service to you.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Trustic
      companies, third-party service providers, other third parties, originating
      banks, purchasers, prospective purchasers, credit bureaus, parties to a
      company transaction, and legally interested parties as described in
      Section 3 below.
    </p>
    <h4>Inference Information.</h4>
    <p className="lead">
      <b>Types.</b> This information includes inferences drawn from any of the
      information in categories listed above. This information may indicate
      characteristics about you, such as preferences, habits, behavior patterns,
      and physical characteristics. For example, your picture may reveal
      physical characteristics, and transactional information may reveal
      patterns in spending, behavior, preferences, and other information.
    </p>
    <p className="lead">
      <b>Sources.</b> We gather the information above from a variety of sources
      as described in each category.
    </p>
    <p className="lead">
      <b>Purposes.</b> We may use this information to improve or expand our
      services or for marketing purposes.
    </p>
    <p className="lead">
      <b>Sharing.</b> We share information in this category with Trustic
      companies, third-party service providers, other third parties, parties to
      a company transaction, and legally interested parties as described in
      Section 3 below.
    </p>
    <h3 className="mb-6 display-5">
      2. HOW WE USE COOKIES AND OTHER TRACKING TECHNOLOGY TO COLLECT
      INFORMATION.
    </h3>
    <p className="lead">
      Like most websites and online services, we and our third-party partners
      automatically collect certain types of usage information when you visit
      our Service, read our emails, or otherwise engage with us. We typically
      collect this information through a variety of tracking technologies, which
      could include cookies, web beacons, Flash cookies (locally stored
      objects), embedded scripts, location-identifying technologies, and similar
      technology (collectively, "tracking technologies"). These tracking
      technologies collect information about how you use the Service (e.g., the
      pages you view, the links you click, and other actions you take on the
      Service), information about your browser and online usage patterns (e.g.,
      IP address, browser type, browser language, referring / exit pages and
      URLs, pages viewed, whether you opened an email, links clicked), and
      information about the device(s) you use to access the Service (e.g.,
      mobile device identifier, mobile carrier, device type, model and
      manufacturer, mobile device operating system brand and model, and
      depending on your mobile device settings, your geographical location data
      (which could include GPS coordinates (e.g. latitude and/or longitude) or
      similar information regarding the location of your mobile device), or we
      may be able to approximate a device's location by analyzing other
      information, like an IP address. We, or our third-party partners, may link
      your various devices so that content you see on one device can result in
      relevant advertising and content displayed on another device, and so that
      we may recognize and contact you on the various devices you may use. We
      may also collect analytics data, or use third-party analytics tools, to
      help us measure traffic and usage trends for the Service. Although we do
      our best to honor the privacy preferences of our visitors, we are not able
      to respond to Do Not Track signals from your browser at this time.
    </p>
    <p className="lead">
      Our websites include social media features, such as the Facebook Like
      button. These features may collect your IP address and other information
      such as the page(s) you are visiting on our websites, and may set a cookie
      to enable the feature to function properly.
    </p>
    <p className="lead">
      Social media features are either hosted by a third party or hosted
      directly on our websites. Your interactions with these features are
      governed by the privacy policy of the company providing it.
    </p>
    <p className="lead">
      We use or may use the data collected through tracking technologies to (a)
      remember information so that you will not have to reenter it during your
      visit or the next time you visit the site; (b) provide custom,
      personalized content and information, including targeted content and
      advertising; (c) recognize and contact you across multiple devices; (d)
      provide and monitor the effectiveness of our Service; (e) monitor
      aggregate metrics such as total number of visitors, traffic, usage, and
      demographic patterns on our websites; (f) diagnose or fix technology
      problems; and (g) otherwise to plan for and enhance our Service.
    </p>
    <p className="lead">
      If you would prefer not to accept cookies, most browsers will allow you to
      adjust your browser settings to: (i) notify you when you receive a cookie,
      which lets you choose whether or not to accept it; (ii) disable existing
      cookies; or (iii) set your browser to automatically reject cookies.
      Blocking or deleting cookies may negatively impact your experience using
      the Service, as some features and services on our Service may not work
      properly. Depending on your mobile device and operating system, you may
      not be able to delete or block all cookies. You may also set your email
      options to prevent the automatic downloading of images that may contain
      technologies that would allow us to know whether you have accessed our
      e-mail and performed certain functions with it.
    </p>
    <p className="lead">
      We and our third-party partners may also use cookies and tracking
      technologies for advertising purposes. For more information about tracking
      technologies, please see "Third-Party Tracking and Online Advertising"
      below.
    </p>
    <h4>3. SHARING OF INFORMATION</h4>
    <p className="lead">
      We may share personal information about you in the instances described
      below. For further information on your choices regarding information about
      you, see the "Control Over Information" section below.
    </p>
    <p className="lead">We may share personal information about you with:</p>
    <p className="lead">
      <b>Originating Banks.</b> In some instances Trustic might work with
      originating banks that could originate your loan. In such cases, if you
      request a loan, you are also providing information to the potential
      originating bank, and additional information may be provided to that bank
      after your loan originates. The use of information about you by your
      originating bank is also governed by that bank's privacy policy;
    </p>
    <p className="lead">
      <b>Trustic Companies.</b> Other companies and brands owned or controlled
      by Nextate Credit, LLC and other companies owned by or under common
      ownership as Nextate Credit, LLC, which also includes our subsidiaries
      (i.e., any organization we own or control) or our ultimate holding company
      (i.e., any organization that owns or controls us) and any subsidiaries it
      owns. These companies will use information about you in the same way as we
      can under this Policy;
    </p>
    <p className="lead">
      <b>Third-Party Service Providers.</b> Third-party vendors and other
      service providers that perform services on our behalf, such as operational
      support services, data processors and companies that help us market our
      own products and third-party products that we believe may be of interest
      to you. We work with a number of service providers who support our
      operations, including but not limited to electronic payment service
      providers and customer support call centers. We share information with
      these third parties to process the transactions you initiate or to perform
      other specific services on our behalf, such as collections or identity
      verification, creating and providing targeted advertisements, or providing
      marketing analytics services. Our service providers are legally required
      to keep personal information private and secure and adhere to applicable
      state and federal laws and regulations and to only use the personal
      information we provide in order to provide such services to us;
    </p>
    <p className="lead">
      <b>Other Third Parties.</b> Third parties at your request, such as
      financial services companies, lenders, insurance companies, retailers,
      membership clubs, or other consumer service providers. For example, if we
      are unable to offer you a loan, you may be given the option to allow us to
      share personal information about you to third parties who may be
      interested in offering you a loan. We will share this information at your
      direction, which may be provided through interaction with or selection of
      a third-party offer presented on one of our websites;
    </p>
    <p className="lead">
      <b>Purchasers.</b> If we sell your loan or a security interest in your
      loan originated through our Service, we may provide the purchaser of your
      loan with information about you and credit information, which may be used
      to process your loan and for other purposes. We may provide the purchaser
      with information about you and other information associated with your loan
      for the purchaser to satisfy a regulatory or legal obligation, even after
      the purchaser has sold the loan to another party. The information we share
      about you will be subject to the purchaser's privacy policy in addition to
      our own. Purchasers may also use this information for their direct
      marketing purposes. To opt-out of such sharing, please see Section 4
      below;
    </p>
    <p className="lead">
      <b>Prospective Purchasers.</b> Prospective purchasers of your loan to
      evaluate the quality of the loan and other service providers involved in
      the sale or potential sale of your loan or an interest in your loan. For
      example, a loan purchaser may need information about you to determine the
      risk associated with the loan;
    </p>
    <p className="lead">
      <b>Credit Bureaus.</b> We may share personal information about you with
      credit bureaus to meet our credit reporting obligations;
    </p>
    <p className="lead">
      <b>Parties to a Company Transaction.</b> Other parties in connection with
      a company transaction, such as a merger, sale of company assets or shares,
      reorganization, financing, change of control or acquisition of all or a
      portion of our business by another company or third party, or in the event
      of a bankruptcy or related or similar proceedings; and
    </p>
    <p className="lead">
      <b>Legally Interested Parties.</b> Third parties as required by law or
      subpoena or if we reasonably believe that such action is necessary to (a)
      comply with the law and the reasonable requests of law enforcement; (b) to
      enforce our <Link to="/terms">Terms of Use</Link> or to protect the
      security or integrity of our Service; and/or (c) to exercise or protect
      the rights, property, or personal safety of Trustic, our visitors, or
      others.
    </p>
    <p className="lead">
      We may also share information with others in an aggregated or otherwise
      de-identified or anonymized form that does not reasonably identify you
      directly as an individual.
    </p>
    <h3 className="mb-6 display-5">4. CONTROL OVER INFORMATION</h3>
    <p className="lead">
      <b>
        Data sharing settings and modifying or deleting information about you:
      </b>{" "}
      You can access and modify information about you (such as name, email
      address, and mailing address) that we collect online and maintain by
      visiting your profile in your Account Summary on our website if you have a
      loan through Trustic. This section of the website is password protected to
      better safeguard information about you. As a registered user, you can
      update your password, email address, physical address, phone number, and
      bank account information at any time on the website. Requests to change
      any other information in your profile or remove your name or comments from
      our website or publicly displayed content can be sent to us at{" "}
      <a href="mailto:support@trustic.com">support@trustic.com</a>. We will
      respond to your request within a reasonable timeframe, but no longer than
      may be required under applicable law (see Section 9 below for requests
      under California privacy law). We may not be able to modify or delete
      information in all circumstances. Due to the regulated nature of our
      industry, we are under legal requirements to retain data and are generally
      not able to delete consumer transactional data upon request. Certain
      regulations issued by state and/or federal government agencies may require
      us to maintain and report demographic information on the collective
      activities of our membership. We may also be required to maintain
      information about you for at least seven years to be in compliance with
      applicable federal and state laws regarding recordkeeping, reporting, and
      audits.
    </p>
    <p className="lead">
      <b>Opt-out from third-party sharing for direct marketing purposes:</b> As
      set forth in Section 9 below, if you are a resident of California and have
      previously opted in to have personal information about you shared with
      third parties for their direct marketing purposes, but no longer wish to
      have such information shared, please email us at{" "}
      <a href="mailto:privacy@trustic.com">privacy@trustic.com</a> to request to
      not have your information shared in this manner.
    </p>
    <p className="lead">
      <b>How to control your communications preferences:</b> You can update your
      email preferences and stop receiving promotional email communications from
      us by clicking on the "Unsubscribe" link provided in such communications.
      We make every effort to process all unsubscribe requests within a
      reasonable amount of time. You may not opt out of Service-related
      communications (e.g., account verification, transactional communications,
      changes/updates to features of the Service, technical and security
      notices).
    </p>
    <h3 className="mb-6 display-5">
      5. THIRD-PARTY TRACKING AND ONLINE ADVERTISING
    </h3>
    <p className="lead">
      <b>Interest Based Advertising.</b> We participate in interest-based
      advertising and use third-party advertising companies to serve you
      targeted advertisements based on your browsing history. We may share, or
      we may permit third-party online advertising networks, social media
      companies and other third-party services, to collect, information about
      your use of our websites over time so that they may play or display ads on
      our Service, on other devices you may use, and on other websites, apps or
      services. Typically, though not always, the information we share is
      provided through cookies or similar tracking technologies, which recognize
      the device you are using and collect information, including click stream
      information, browser type, time and date you visited the site and other
      information. We and our third-party partners may combine this information
      with information collected offline or from other sources. We and our
      third-party partners use this information to make the advertisements you
      see online more relevant to your interests, as well as to provide
      advertising- related services such as reporting, attribution, analytics
      and market research.
    </p>
    <p className="lead">
      <b>Social Media Widgets and Advertising.</b> Our Service may include
      social media features, such as the Facebook Like button, Google Plus,
      Twitter or other widgets. These social media companies may recognize you
      and collect information about your visit to our Service, and they may set
      a cookie or employ other tracking technologies. Your interactions with
      those features are governed by the privacy policies of those companies.
    </p>
    <p className="lead">
      We display targeted advertising to you through social media platforms,
      such as Facebook, Twitter, Google+ and others. These companies have
      interest-based advertising programs that allow us to direct advertisements
      to users who have shown interest in our Service while those users are on
      the social media platform, or to groups of other users who share similar
      traits, such as likely commercial interests and demographics. These
      advertisements are governed by the privacy policies of those social media
      companies that provide them.
    </p>
    <p className="lead">
      <b>Cross-Device Linking.</b> We, or our third-party partners, may link
      your various devices so that content you see on one device can result in
      relevant advertising on another device. We do this by collecting
      information about each device you use when you are logged in to our
      Service. We may also work with third-party partners who employ tracking
      technologies, or the application of statistical modeling tools, to
      determine if two or more devices are linked to a single user or household.
      We may share a common account identifier (such as an email address or user
      ID) with third-party advertising partners to help recognize you across
      devices. We, and our partners, can use this cross-device linkage to serve
      interest-based advertising and other personalized content to you across
      your devices, to perform analytics and to measure the performance of our
      advertising campaigns.
    </p>
    <h4>Your Choices:</h4>
    <p className="lead">
      Interest-based advertising. To learn about interest-based advertising and
      how you may be able to opt-out of some of this advertising, you may wish
      to visit the Network Advertising Initiative (NAI) online resources, at
      http://www.networkadvertising.org/choices, and/or the Digital Advertising
      Alliance (DAA) resources athttp://www.aboutads.info/choices.
    </p>
    <p className="lead">
      Cross-device linking. Please note that opting-out of receiving
      interest-based advertising through the NAI's and DAA's online resources
      will only opt-out a user from receiving interest-based ads on that
      specific browser or device, but the user may still receive interest-based
      ads on his or her other devices. You must perform the opt-out on each
      browser or device you use.
    </p>
    <p className="lead">
      Mobile advertising. You may also be able to limit interest-based
      advertising through the settings on your mobile device by selecting "limit
      ad tracking" (iOS) or "opt-out of interest based ads" (Android). You may
      also be able to opt-out of some – but not all – interest-based ads served
      by mobile ad networks by visiting http://youradchoices.com/appchoices and
      downloading the mobile AppChoices app.
    </p>
    <p className="lead">
      Some of these opt-outs may not be effective unless your browser is set to
      accept cookies. If you delete cookies, change your browser settings,
      switch browsers or computers, or use another operating system, you will
      need to opt-out again.
    </p>
    <p className="lead">
      Google Analytics and Advertising. We use Google Analytics to recognize you
      and link the devices you use when you visit our websites or Service on
      your browser or mobile device, login to your account on our Service, or
      otherwise engage with us. We may share a unique identifier, like a user ID
      or hashed email address, with Google to facilitate the Service. Google
      Analytics allows us to better understand how our users interact with our
      Service and to tailor our advertisements and content to you. For
      information on how Google Analytics collects and processes data, as well
      as how you can control information sent to Google, review Google's site
      "How Google uses data when you use our partners' sites or apps" located
      athttps://www.google.com/policies/privacy/partners/. You can learn about
      Google Analytics' currently available opt-outs, including the Google
      Analytics Browser Ad-On here https://tools.google.com/dlpage/gaoptout/.
    </p>
    <p className="lead">
      We may also utilize certain forms of display advertising and other
      advanced features through Google Analytics, such as Remarketing with
      Google Analytics, Google Display Network Impression Reporting, the
      DoubleClick Campaign Manager Integration, and Google Analytics
      Demographics and Interest Reporting. These features enable us to use
      first-party cookies (such as the Google Analytics cookie) and third-party
      cookies (such as the Doubleclick advertising cookie) or other third-party
      cookies together to inform, optimize, and display ads based on your past
      visits to the Service. You may control your advertising preferences or
      opt- out of certain Google advertising products by visiting the Google Ads
      Preferences Manager, currently available at
      https://google.com/ads/preferences or by vising NAI's online resources at
      http://www.networkadvertising.org/choices.
    </p>
    <h3 className="mb-6 display-5">
      6. HOW WE PROTECT AND STORE INFORMATION ABOUT YOU
    </h3>
    <p className="lead">
      <b>Keeping information safe:</b> As a financial services company, Trustic
      understands that the information collected and shared with us by our
      borrowers, investors and business partners contains sensitive data. We
      take our role in safeguarding information about you seriously. We maintain
      a security program that draws on industry standards and best practices. We
      take a defensive, in-depth approach to implementing physical,
      administrative, and technical safeguards to protect information against
      unauthorized access or misuse. The safeguards include preventive,
      detective, and corrective controls, for example: physical safeguards like
      access badges and a video monitoring system; administrative safeguards
      like an employee security training program, employment background checks,
      and access controls; and technical safeguards like perimeter security,
      encryption, two-factor authentication, and regular security monitoring
      with layered and complementary controls. In addition, we use:
    </p>
    <ul>
      <li>
        Encryption & website certificates. Encryption protects the information
        you share with us from the moment it leaves your computer until it
        reaches our systems. Look for the secure locked padlock on your browser,
        which signifies a secure web connection. Consult your web browser
        documentation for more information.
      </li>
      <li>
        Session time-outs. We employ session time-outs that will log you out of
        the website automatically after a period of inactivity. This reduces the
        risk of others being able to access your account if you leave your
        computer unattended.
      </li>
      <li>
        Passwords. You should never share your password with anyone. Your
        password is not known to any Trustic employee or third party, and we
        will never ask for your password by phone or email. If you ever receive
        a communication claiming to be from Trustic that asks for your password,
        you should immediately report it by contacting us at
        security@trustic.com.
      </li>
      <li>
        Identity theft protection. In order to protect your identity at Trustic,
        we have developed and implemented an identity theft prevention and
        response program that is designed to detect, prevent, and mitigate
        identity theft when borrowing through the Trustic platform. We utilize
        authentication technology to verify the identities of our borrowers and
        guarantors, and have established processes, procedures, and training for
        Trustic employees to identify and respond appropriately to identity
        theft indicators. Under our agreements with investors, we are obligated
        to repurchase loans in cases of confirmed borrower identity theft.
      </li>
    </ul>
    <p className="lead">
      We also periodically review and adjust security safeguards as the threat
      landscape evolves. Ultimately no security system is impenetrable, and we
      cannot guarantee the security of our systems 100%. In the event that any
      information under our control is compromised as a result of a breach of
      security, our policy is to take reasonable steps to investigate the
      situation and, where appropriate, communicate with affected individuals.
    </p>
    <p className="lead">
      <b>Data storage and transfer:</b> Information collected through our
      websites may be stored and processed in the United States or any other
      country in which we or our affiliates or service providers maintain
      facilities. If you are located in the European Union or other regions with
      laws governing data collection and use that may differ from U.S. law,
      please note that we may transfer information, including personal
      information, to a country and jurisdiction that does not have the same
      data protection laws as your jurisdiction, and you consent to the transfer
      of information to the U.S. or any other country in which we or our parent,
      subsidiaries, affiliates, or service providers maintain facilities and the
      use and disclosure of information about you as described in this
      <a href="#online-privacy-notice"> Privacy Policy</a>.
    </p>
    <h3 className="mb-6 display-5">7. CHILDREN'S PRIVACY</h3>
    <p className="lead">
      We do not knowingly collect or solicit any information from anyone under
      the age of 13 on this Service. In the event that we learn that we have
      inadvertently collected personal information from a child under age 13, we
      will delete that information as quickly as possible. If you believe that
      we might have any information from a child under 13, please contact us at{" "}
      <a href="mailto:privacy@trustic.com">privacy@trustic.com</a>.
    </p>
    <h3 className="mb-6 display-5">8. LINKS TO OTHER WEBSITES AND SERVICES</h3>
    <p className="lead">
      The Service may contain links to and from third-party websites of our
      business partners, advertisers, and social media sites and our users may
      post links to third-party websites. If you follow a link to any of these
      websites, please note that these websites have their own privacy policies
      and that we do not accept any responsibility or liability for their
      policies. We strongly recommend that you read their privacy policies and
      terms and conditions of use to understand how they collect, use, and share
      information. We are not responsible for the privacy practices or the
      content on third-party websites.
    </p>
    <h3 className="mb-6 display-5">9. YOUR CALIFORNIA PRIVACY RIGHTS</h3>
    <p className="lead">
      In California, you may opt-in to have personal information about you
      shared with third parties for their direct marketing purposes. Consistent
      with California Civil Code Section 1798.83, if you no longer wish to have
      personal information about you shared with third parties for their direct
      marketing purposes, please email us at{" "}
      <a href="mailto:privacy@trustic.com">privacy@trustic.com</a> to request to
      not have your information shared in this manner.
    </p>
    <p className="lead">
      The California Consumer Privacy Act or CCPA provides for additional rights
      for California consumers. Your rights under the CCPA include: a) the right
      to request that a business that collects a consumer's personal information
      disclose to that consumer the categories and specific pieces of personal
      information the business has collected; b) the right to request that a
      business delete certain personal information about the consumer which the
      business has collected from the consumer; c) the right to request that a
      business that collects personal information about the consumer disclose to
      the consumer the categories of personal information it has collected about
      that consumer, the categories of sources from which the personal
      information is collected, the business or commercial purpose for
      collecting or selling personal information, the categories of third
      parties with whom the business shares personal information, and the
      specific pieces of personal information it has collected about that
      consumer; d) the right to request that a business that sells the
      consumer's personal information, or that discloses it for a business
      purpose, disclose to that consumer the categories of personal information
      that the business sold or disclosed; and e) the right to opt-out of the
      sale of personal information.
    </p>
    <p className="lead">
      These rights do not apply to personal information collected, processed,
      sold, or disclosed pursuant to the federal Gramm-Leach-Bliley Act (GLBA),
      including certain information relating to financial services we offer such
      as information about financial accounts, credit information, or
      information collected as part of the application process. Most of the
      information we collect is subject to GLBA and therefore not subject to the
      CCPA and the rights described above, However, to the extent your
      information is subject to the CCPA, you may exercise your rights under the
      CCPA by emailing us at{" "}
      <a href="mailto:privacy@trustic.com">privacy@trustic.com</a>. If you have
      a loan with us, access to your loan information is conveniently available
      by logging into your account.
    </p>
    <p className="lead">
      Trustic does not sell your personal information as described in the CCPA.
    </p>
    <p className="lead">
      Trustic does not sell personal information of minors as described in the
      CCPA.
    </p>
    <p className="lead">
      You have a right not to receive discriminatory treatment by the business
      for the exercise of the privacy rights conferred by the CCPA. Also, you
      may designate an authorized agent to make a request under the CCPA on your
      behalf.
    </p>
    <h3 className="mb-6 display-5">10. HOW TO CONTACT US</h3>
    <p className="lead">
      If you have any questions about this
      <a href="#online-privacy-notice"> Privacy Policy</a> or our websites,
      please contact us at{" "}
      <a href="mailto:privacy@trustic.com">privacy@trustic.com</a>.
    </p>
    <p className="lead">
      If you have a privacy or data use concern, please contact us first at{" "}
      <a href="mailto:privacy@trustic.com">privacy@trustic.com</a>. If we have
      not addressed your privacy or data use concern satisfactorily, please
      contact our U.S.-based, privacy and data usage third-party dispute
      resolution provider (free of charge) at
      https://feedback-form.truste.com/watchdog/request.
    </p>
    <h3 className="mb-6 display-5">11. CHANGES TO OUR PRIVACY POLICY</h3>
    <p className="lead">
      We may amend this
      <a href="#online-privacy-notice"> Privacy Policy</a> at any time by
      posting a revised version on our website. The revised version will be
      effective as of the published effective date. If there are substantial
      changes to our sharing practices, we will provide you with notice 30 days
      prior to the effective date which we will post in our Policy Update page
      of our website. After the 30-day notice, your continued use of the Service
      constitutes express acceptance to all changes to the
      <a href="#online-privacy-notice"> Privacy Policy</a>. This Policy was last
      updated on May 20, 2021.
    </p>
  </div>
)

const Privacy = () => (
  <>
    <Layout
      headerComponent={
        <header className="header pt-8 pb-4 bg-dark text-white text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <h1>Privacy Policy</h1>
                <p className="lead-2 opacity-90 mt-6">
                  Last update: May 20, 2021
                </p>
              </div>
            </div>
          </div>
        </header>
      }
    >
      <section className="section py-7">
        <div className="container">
          <div className="row">{privacyHtmlContent}</div>
        </div>
      </section>
    </Layout>
    <Seo
      title="Trustic | Privacy Policy"
      description="Your questions answered about guarantor personal loans from Trustic."
    />
  </>
)

export default Privacy

export { privacyHtmlContent }
